import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import store from "../store/index";
import { navigateTo } from "@/router/navigateTo";
import { lowerJSONKey } from "@/utils/common";

const routes = [
  // {
  //     path: '/',
  //     name: 'home',
  //     redirect: "quote"+`${'#' + store.state.steps.current}`,
  //     component: () => import('@/views/home'),
  //     hidden: true
  // },
  {
    path: '/',
    name: 'quote',
    // redirect: "/"+`${'#' + store.state.steps.current}`,
    component: () => import('@/views/quotePage/index'),
    hidden: true
  },
  // {
  //   path: '/result',
  //   name: 'result',
  //   component: () => import('@/views/resultPage/index'),
  //   hidden: true
  // },
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/resultPage/index2'),
    hidden: true
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path==="/") {
    // console.log(7777,!store.state.isInternalNavigation);
    // 禁止广告页跳转 具体问题页，只能是根路由
    if(from.path === "/result"&&to.hash!==''){
        next(false); //取消导航
    } else {
      // console.log(111);
      
      // 无锚点
      if (to.hash==='') {
        // console.log(222);
        
        // 有缓存，跳转到缓存问题页
        if(store.state.steps.current){
          // 跳到缓存的当前锚点，预防其还未点击过 下一步按钮，设置setIsInternalNavigation通道
          store.dispatch("setIsInternalNavigation", true)
          next({...to, hash:'#'+store.state.steps.current})
        } else{
          // 防止缓存空时，跳转错误
          next({...to, hash:'#LoanPurpose_Mortgage'})
        }
      }else{
        // 有锚点，但是否存在历史记录中
        const hasHash =  store.state.steps.historyList.some(item=>item===to.hash?.split('#')[1]) || store.state.steps.current === to.hash?.split('#')[1]
        // console.log("456",to);
        
        // 前往首页锚点  或者  记录存在  或者  通过点击按钮跳转的
        if (to.hash==='#LoanPurpose_Mortgage' || hasHash || store.state.isInternalNavigation) {
          // console.log(333);
          
          // console.log(9999,to);
          next(); // 正常进行路由跳转
          store.dispatch("setStepsCurrent", to.hash?.split('#')[1])
          store.dispatch("setIsInternalNavigation", false) // 重置状态标志
        } else{
          // console.log(444,to.hash);
          
          next({...to, hash:'#LoanPurpose_Mortgage'})
          store.dispatch("setStepsCurrent", 'LoanPurpose_Mortgage')
          store.dispatch("setStepsHistoryList", [])
        }
        // next(); // 正常进行路由跳转
        // store.dispatch("setStepsCurrent", to.hash?.split('#')[1])
      }
    }
  } else {
    next()
    // 跳转非首页'/' （如：广告页）时，重置
    store.dispatch("setIsInternalNavigation", false) // 重置状态标志
  }
  
  // if (to.path==="/quote") {
  //   // console.log(7777,!store.state.isInternalNavigation);
  //   if((!store.state.isInternalNavigation && from.path !== "/" ) || from.path === "/result"){
  //       // console.log("禁止通过浏览器跳转页面");
  //       next(false); //取消导航
  //       // next(back); //取消导航
  //       // store.dispatch("setIsInternalNavigation", true)
  //       // router.back()
  //       // navigateTo(router, back); // 返回上一页
  //   } else {
  //     // console.log(9999,to);
  //     if (to.hash) {
  //       next(); // 正常进行路由跳转
  //       store.dispatch("setStepsCurrent", to.hash?.split('#')[1])
  //     }else{
  //       // next('/quote#'+store.state.steps.current)
  //       next('/quote#LoanPurpose_Mortgage')
  //     }
  //   }
  // } else {
  //   next()
  // }
    
    // setTimeout(() => {
    //   console.log(1111,store.state.questionAnswer[from.hash?.split('#')[1]], Object.keys(store.state.questionAnswer)
    //   ?.some(item=>item===to.hash?.split('#')[1]));
    //   if (store.state.questionAnswer[from.hash?.split('#')[1]] || 
    //       Object.keys(store.state.questionAnswer)
    //       ?.some(item=>item===to.hash?.split('#')[1])
    //     ) {
    //       console.log(222);
          // store.dispatch("setStepsCurrent", to.hash?.split('#')[1])
    //       return true
    //   } else{
    //       console.log(333);
    //       router.back()
    //       // store.dispatch("setStepsCurrent", n.split('#')[1])
    //   }
    // },0)

    // 获取进入表单的开始事件
    if (to.hash==="#LoanPurpose_Mortgage"&&from.path==="/") {
      const startTime = new Date()
      // console.log("startTime", startTime.getTime());
      store.dispatch("setFormStayTime_start", startTime.getTime())
      // console.log(111,to);
      const query = lowerJSONKey({...to.query})
 
      const queryIdObj = {
        affiliateid: query.affiliateid,
        campaignid: query.campaignid,
        offerid: query.offerid,
        aff_sub1: query.aff_sub1,
        aff_sub2: query.aff_sub2,
        aff_sub3: query.aff_sub3,
      }
      store.dispatch("setQueryIdObj", queryIdObj)
    }
})

export default router
